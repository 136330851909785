h1 {
  color: hotpink;
  font-family: cursive;
}

#control {
}

#download-canvas {
  display: none;
}

#control-layer {
  width: 100%;
  display: flex;
  overflow-x: scroll;
}

.control-item {
  width: 300px;
  margin-right: 50px;
}

.control-input {
  width: 100%;
  display: flex;
}
.control-input label {
  width: 100px;
}

.control-input input {
  width: 150px;
}
